/**
 * @flow
 */
import * as React from 'react';
import {api, util} from "../../../services/service";
import {LoadingContainer, TabButton} from "../CommonStyle";
import MasterBLHbl from "../MasterBLHbl";
import MovingMasterBLFormManager from "../../../forms/newCoship/moving/MovingMasterBLFormManager";
import {PrimaryButton} from "../../../components/buttons";
import MovingMasterBLMblFormManager from "../../../forms/newCoship/moving/MovingMasterBLMblFormManager";
import useMovingMasterBLAPModal from "../../../modals/newCoship/moving/useMovingMasterBLAPModal";
import {getBLData} from "../../../forms/field-defs/newCoship";
import {apiMoving} from "../../../services/newCoship/service";
import {setFilingNo, setHouseAPTotal} from "../../../redux/reducers/newCoshipReducer";
import ReactLoading from "react-loading";
import {useSelector} from "react-redux";
import {useContainerDetailPreviewModal} from "../../../print-layouts/ContainerDetailPrint";
import {useUser} from "../../../redux/reducers/userReducer";
import {useContainerLoadPlanPreviewModal} from "../../../print-layouts/ContainerLoadPlanPrint";
import {useTopbar} from "../../../redux/reducers/topbarReducer";
import HouseBLAccountItemFormManager from "../../../forms/newCoship/HouseBLAccountItemFormManager";

const masterBLForm = new MovingMasterBLFormManager();
const mblForm = new MovingMasterBLMblFormManager();
const accountAPItemForm = new HouseBLAccountItemFormManager('AP', 'M');

const TABS = [
  {index: 1, path: '/admin/moving/master/mbl'},
  {index: 2, path: '/admin/moving/master/hbl'},
  {index: 3, path: '/admin/moving/master/ap'}
];

const MovingMasterBL = () => {
  useTopbar({label: 'Moving'}, {label: 'Master BL'});
  const qs = util.getQS();
  const {id} = qs;
  const pathname = window.location.pathname;
  const user = useUser();
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [clickedIndex, setClickedIndex] = React.useState<number>(1);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isShowHblList, setIsShowHblList] = React.useState<boolean>(false);
  const [hblList, setHblList] = React.useState([]);
  const [containerId, setContainerId] = React.useState(0);
  const [footer, setFooter] = React.useState();
  const {filingNo, apTotal} = useSelector(state => state['newCoship']);
  const style1 = {backgroundColor: clickedIndex === TABS[0].index ? '#b4c7e7' : 'white'};
  const style2 = {backgroundColor: clickedIndex === TABS[1].index ? '#b4c7e7' : 'white'};
  const style3 = {backgroundColor: clickedIndex === TABS[2].index ? '#b4c7e7' : 'white'};

  const onClickTab = (tabIndex) => {
    setClickedIndex(tabIndex);
    util.nav(id ? `${TABS[tabIndex-1].path}?id=${id}` : TABS[tabIndex-1].path);
  };

  const {...apModal} = useMovingMasterBLAPModal(async (mode, data) => {
    if(mode === 'print') {
      await onAPPrint(data);
    } else {
      await onSaveAP(data);
    }
  });

  const onSearch = async () => {
    if (id) {
      apiMoving.getBLData({id: util.toInt(id)}).then((res) => {
        if (res) {
          const data = res.data;
          const blData = {id, ...data.jcommon, ...data.jcustomer, ...data.jshipment, ...data.jroute, ...data.jvessel, ...data.containers?.[0]};
          masterBLForm.setValues(blData);
          mblForm.setValues(blData);
          accountAPItemForm.setData(data.ap_invoice_nos);
          setHblList(data.houses);
          setIsEdit(true);
          setContainerId(data.containers?.[0]?.container_id);
          setFooter(data.footer);
          setFilingNo(util.dispatch, data.filing_no);
          setHouseAPTotal(util.dispatch, data.account_tab?.ap_amount);
        } else {
          setIsEdit(false);
        }
      });
    } else {
      masterBLForm.clearValues();
      mblForm.clearValues();
      accountAPItemForm.setData([]);
      setHblList([]);
      setFilingNo(util.dispatch, '');
      setHouseAPTotal(util.dispatch, '');
      // default setting
      masterBLForm.setValue('branchId', user.branch_id);
    }
  };

  const onSave = async () => {
    const masterBLFormValues = masterBLForm.getValues();
    const mblFormValues = mblForm.getValues();
    const values = {...masterBLFormValues, ...mblFormValues, filing_no: filingNo, id: id ? util.toInt(id) : undefined};
    const payload = getBLData(true, values);
    setShowLoading(true);
    await apiMoving.setMovingBL(payload).then(res => {
      if (res) {
        util.nav(`${pathname}?id=${res.data.id}`);
        util.showSuccess('Master BL has been saved successfully!');
        setShowLoading(false);
        onSearch().catch();
      }
    });
  };

  const onSaveAP = (data) => {
    console.log('onSaveAP: ', data);
  };

  const onCLPPrint = () => {
    api.craftContainerItemList({id: containerId}).then((res) => {
      if (res.data) {
        containerLoadPlanPrintModal.open(res.data[0], 'print');
      }
    });
  };

  const onSDPrint = () => {
    api.craftContainerItemList({id: containerId}).then((res) => {
      if (res.data) {
        containerDetailPrintModal.open(res.data[0], 'print');
      }
    });
  };

  const onAPPrint = () => {
    console.log('onAPPrint');
  };

  const containerDetailPrintModal = useContainerDetailPreviewModal();
  const containerLoadPlanPrintModal = useContainerLoadPlanPreviewModal();

  const renderButton = () => {
    return (
      <div className={'flex'} style={{justifyContent: 'center', marginTop: 30}}>
        <div className={'mr-2'}><PrimaryButton label={'CLP PRINT'} disabled={!isEdit} onClick={onCLPPrint}/></div>
        <div className={'mr-2'}><PrimaryButton label={'SD PRINT'} disabled={!isEdit} onClick={onSDPrint}/></div>
        {clickedIndex === 1 && <div><PrimaryButton label={'SAVE'} onClick={onSave} noMargin/></div>}
      </div>
    );
  };

  React.useEffect(() => {
    onSearch().catch();
  }, [id]);

  React.useEffect(() => {
    if (pathname === TABS[1].path) {
      setClickedIndex(TABS[1].index);
      mblForm.isShowForm = false;
      setIsShowHblList(true);
      accountAPItemForm.isShowForm = false;
    } else if (pathname === TABS[2].path) {
      setClickedIndex(TABS[2].index);
      mblForm.isShowForm = false;
      setIsShowHblList(false);
      accountAPItemForm.isShowForm = true;
      accountAPItemForm.blId = id;
    } else {
      setClickedIndex(TABS[0].index);
      mblForm.isShowForm = true;
      setIsShowHblList(false);
      accountAPItemForm.isShowForm = false;
    }
    util.nav(id ? `${pathname}?id=${id}` : pathname);
  }, [id, pathname]);

  return (
    <>
      <div style={{marginLeft: -20}}>
        <div className={'flex'} style={{justifyContent: 'flex-end'}}>
          <div style={{marginTop: -25}}>
            {util.renderFooter(footer, 'new-house-footer')}
          </div>
        </div>
        {masterBLForm.renderForm()}
      </div>
      <hr/>
      <div style={{marginTop: 20}}>
        {showLoading && <LoadingContainer><ReactLoading color={'grey'} type={'spin'} height={100} width={100}/></LoadingContainer>}
        <div className={'flex'} style={{marginLeft: -2}}>
          <TabButton style={style1} onClick={() => onClickTab(TABS[0].index)}><div>MBL INFO</div></TabButton>
          {isEdit ?
            <TabButton style={style2} onClick={() => onClickTab(TABS[1].index)}><div>HBL LIST</div></TabButton> :
            <TabButton style={{cursor: 'not-allowed'}}><div>HBL LIST</div></TabButton>
          }
          {isEdit ?
            <TabButton style={style3} onClick={() => onClickTab(TABS[2].index)}><div>AP</div></TabButton> :
            <TabButton style={{cursor: 'not-allowed'}}><div>AP</div></TabButton>
          }
        </div>
        <div style={{backgroundColor: 'white', minHeight: 600, border: '1px solid'}}>
          {mblForm.renderForm()}
          {accountAPItemForm.renderForm()}
          <MasterBLHbl isShowList={isShowHblList} hblList={hblList} kind={'M'}/>
        </div>
        {renderButton()}
        {apModal.render()}
        {containerDetailPrintModal.render()}
        {containerLoadPlanPrintModal.render()}
      </div>
    </>
  );
};

export default MovingMasterBL;
