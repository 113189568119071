/**
 * @flow
 */
import * as React from 'react';
import {api, apiSettings, util} from "../../../services/service";
import {TabButton} from "../CommonStyle";
import ForwardingHouseBLFormManager from "../../../forms/newCoship/forwarding/ForwardingHouseBLFormManager";
import {DangerButton, PrimaryButton} from "../../../components/buttons";
import useForwardingHouseBLPackingListModal from "../../../modals/newCoship/forwarding/useForwardingHouseBLPackingListModal";
import ForwardingHouseBLCustomerFormManager from "../../../forms/newCoship/forwarding/ForwardingHouseBLCustomerFormManager";
import ForwardingHouseBLWorkDetailsFormManager from "../../../forms/newCoship/forwarding/ForwardingHouseBLWorkDetailsFormManager";
import HouseBLWorkOrdersFormManager from "../../../forms/newCoship/HouseBLWorkOrdersFormManager";
import {getBLData} from "../../../forms/field-defs/newCoship";
import {apiForwarding} from '../../../services/newCoship/service';
import HouseBLAccountItemFormManager from "../../../forms/newCoship/HouseBLAccountItemFormManager";
import ReactLoading from "react-loading";
import {LoadingContainer} from "../CommonStyle";
import HouseBLAccountList from "../HouseBLAccountList";
import {
  setBlStatus,
  setBound, setCBM,
  setFilingNo,
  setHouseAccountARData, setHouseAccountDeposit,
  setHouseAccountList, setKind, setPKG, setProfitArray, setCarrierType
} from "../../../redux/reducers/newCoshipReducer";
import useEmailModal from "../../../modals/email/useEmailModal";
import {useSendEmailModal} from "../../../forms/email/SendEmailFormManager";
import {useOEHBLPrintModal} from "../../../forms/print/v2/OEHBLPrintForm";
import {useAEMBLPrintModal} from "../../../forms/print/v2/AEMBLPrintForm";
import {useUser} from "../../../redux/reducers/userReducer";
import {usePackingListPreviewModal} from "../../../print-layouts/PackingListPrint";
import type {ForwardingType} from "../../../forms/BLFormManager";
import {useMarkOEHModal} from "../../../forms/mark/MarkOEHFormManager";
import {useMarkOIHModal} from "../../../forms/mark/MarkOIHFormManager";
import {useMarkAEHModal} from "../../../forms/mark/MarkAEHFormManager";
import {useMarkAIHModal} from "../../../forms/mark/MarkAIHFormManager";
import {useSelector} from "react-redux";
import {useTopbar} from "../../../redux/reducers/topbarReducer";
import {useInvoiceARPreviewModal} from "../../../print-layouts/InvoiceARPrintNew";
import {useInvoiceAPPreviewModal} from "../../../print-layouts/InvoiceAPPrintNew";
import {useInvoiceDCPreviewModal} from "../../../print-layouts/InvoiceDCPrintNew";

const houseBLForm = new ForwardingHouseBLFormManager();
const customerForm = new ForwardingHouseBLCustomerFormManager();
const workDetailsForm = new ForwardingHouseBLWorkDetailsFormManager();
const workOrdersForm = new HouseBLWorkOrdersFormManager('F');
const accountARItemForm = new HouseBLAccountItemFormManager('AR', 'F');
const accountAPItemForm = new HouseBLAccountItemFormManager('AP', 'F');
const accountDCItemForm = new HouseBLAccountItemFormManager('DC', 'F');

const TABS = [
  {index: 1, path: '/admin/forwarding/house/customer'},
  {index: 2, path: '/admin/forwarding/house/work'},
  {index: 3, path: '/admin/forwarding/house/ar'},
  {index: 4, path: '/admin/forwarding/house/ap'},
  {index: 5, path: '/admin/forwarding/house/dc'},
  {index: 6, path: '/admin/forwarding/house/accountlist'}
];

const ForwardingHouseBL = () => {
  useTopbar({label: 'Forwarding'}, {label: 'House BL'});
  const qs = util.getQS();
  const {id, qid, mblId, cid} = qs;
  const user = useUser();
  const [isEdit, setIsEdit] = React.useState(false);
  const [clickedIndex, setClickedIndex] = React.useState<number>(1);
  const [showLoading, setShowLoading] = React.useState<boolean>(false);
  const [isShowAccountList, setIsShowAccountList] = React.useState<boolean>(false);
  const [type, setType] = React.useState<ForwardingType>('');
  const [currentId, setCurrentId] = React.useState<ForwardingType>('');
  const [isFlag, setIsFlag] = React.useState<boolean>(false);
  const [blData, setBlData] = React.useState();
  const [footer, setFooter] = React.useState();
  const {filingNo, carrierType, kind, bound, profitArray, arData, deposit} = useSelector(state => state['newCoship']);
  const pathname = window.location.pathname;

  const onSendEmail = (mode, data) => {
    const {pic_email, pic_name, toEmail, template, title, message, selectedFile, blId, partnerId} = data;
    if(!template && mode === 'send') {
      return util.showWarning('Please select template!');
    }
    if(!pic_email) {
      return util.showWarning('Please enter a valid email!');
    }
    if(!message) {
      return util.showWarning('Please enter a valid message!');
    }
    const formData = new FormData();
    formData.append('blId', blId);
    formData.append('partnerId', partnerId);
    formData.append('from', pic_email);
    formData.append('fromName', pic_name);
    formData.append('to', toEmail);
    formData.append('subject', title);
    formData.append('message', message);
    formData.append('houses', blId);
    formData.append('file', selectedFile);
    const res = apiSettings.sendEmail(formData);
    if(res) {
      util.showSuccess('Email has been sent successfully.');
      sendEmailModal.close();
      emailModal.onSearch().catch();
    }
  };

  const onSearch = async () => {
    if (id || mblId) {
      const blId = id ? id : mblId;
      apiForwarding.getBLData({id: util.toInt(blId)}).then((res) => {
        if (res) {
          const data = res.data;
          let blData = {id: data.id, bl_status: data.bl_status, ...data.jcommon, ...data.jcustomer, ...data.jshipment, ...data.jroute, ...data.jvessel};
          if (mblId) {
            blData = {...data.jcommon, ...data.jroute, ...data.jvessel};
          }
          houseBLForm.setValues(blData);
          customerForm.setValues(blData);
          workDetailsForm.setValues(blData);
          workOrdersForm.setData(data.work_order);
          accountARItemForm.setData(data.ar_invoice_nos);
          accountAPItemForm.setData(data.ap_invoice_nos);
          accountDCItemForm.setData(data.dc_invoice_nos);
          setIsEdit(true);
          setIsFlag(data.flag);
          const newType = (data.carrier_type ?? 'O').charAt(0) + (data.bound ?? 'E') + 'H';
          setType(newType);
          setCurrentId(id);
          setBlData(blData);
          setFooter(data.footer);
          if (!blData.shipperAddrEng && blData.shipperAddr) {
            customerForm.setValue('shipperAddrEng', blData.shipperAddr);
          }
          setFilingNo(util.dispatch, data.filing_no);
          setKind(util.dispatch, data.kind);
          setBound(util.dispatch, data.bound);
          setBlStatus(util.dispatch, data.bl_status);
          setHouseAccountList(util.dispatch, data.ar_ap_list);
          setProfitArray(util.dispatch, data.profit_array);
          setHouseAccountARData(util.dispatch, data.account.ar_data);
          setHouseAccountDeposit(util.dispatch, data.account.deposit);
          setCBM(util.dispatch, data.jshipment.cbm);
          setPKG(util.dispatch, data.jshipment.package);
        } else {
          setIsEdit(false);
        }
      });
    } else {
      houseBLForm.clearValues();
      customerForm.clearValues();
      workDetailsForm.clearValues();
      workOrdersForm.setData([]);
      accountARItemForm.setData([]);
      accountAPItemForm.setData([]);
      accountDCItemForm.setData([]);
      setFilingNo(util.dispatch, undefined);
      setBound(util.dispatch, 'E');
      setBlStatus(util.dispatch, 'NG');
      setHouseAccountList(util.dispatch, []);
      setProfitArray(util.dispatch, []);
      setHouseAccountARData(util.dispatch, []);
      setHouseAccountDeposit(util.dispatch, []);
      setCBM(util.dispatch, '');
      setPKG(util.dispatch, '');
      // default setting
      houseBLForm.setValue('branchId', user.branch_id);
    }
  };

  const onSearchCopy = async () => {
    if (cid) {
      api.forwardViewBLCopyEntry({id: util.toInt(cid)}).then((res) => {
        if (res) {
          const data = res.data[0];
          const jcustomer = data.jcustomer;
          // console.log('jcustomer:', jcustomer);
          jcustomer.shipperAddr = jcustomer.shipperAddr || jcustomer?.shipperAddrEng;
          jcustomer.shipperAddrEng = jcustomer?.shipperAddrEng || jcustomer.shipperAddr;
          const blData = {
            ...data.jcommon, ...jcustomer,
            mblNo: undefined, hblNo: undefined, mawbNo: undefined, hawbNo: undefined, pDate: util.getCurrentDate(),
            consigneeAddr: undefined, consigneeAddrLocal: undefined, consigneeCity: undefined, consigneeProvince: undefined,
            consigneeCountry: undefined, consigneePostalCode: undefined, buildingCode: undefined, totalPrice: undefined,
            remark: undefined, remarkD: undefined, paidBy: "CARD", pTime: undefined
          };
          houseBLForm.setValues(blData);
          customerForm.setValues(blData);
          setKind(util.dispatch, data.kind);
          setCarrierType(util.dispatch, data.carrier_type);
          setBound(util.dispatch, data.bound);
        }
      });
    }
  };

  const onClickTab = (tabIndex) => {
    setClickedIndex(tabIndex);
    customerForm.isShowForm = tabIndex === TABS[0].index;
    workDetailsForm.isShowForm = tabIndex === TABS[1].index;
    workOrdersForm.isShowForm = tabIndex === TABS[1].index;
    accountARItemForm.isShowForm = tabIndex === TABS[2].index;
    accountAPItemForm.isShowForm = tabIndex === TABS[3].index;
    accountDCItemForm.isShowForm = tabIndex === TABS[4].index;
    if (id) {
      util.nav(`${TABS[tabIndex-1].path}?id=${id}`);
    } else if (qid) {
      util.nav(`${TABS[tabIndex-1].path}?qid=${qid}`);
    } else if (mblId) {
      util.nav(`${TABS[tabIndex-1].path}?mblId=${mblId}`);
    } else {
      util.nav(TABS[tabIndex-1].path);
    }
  };

  const onSaveMark = async (mode, data) => {
    if (!id) return;
    const res = await api.forwardSetBLMark({id, jmark: data});
    if (res) {
      util.showSuccess('BL Mark & Desc. has been saved successfully.');
      switch (type) {
        case 'OEH': oehMarkModal.close(); break;
        case 'OIH': oihMarkModal.close(); break;
        case 'AEH': aehMarkModal.close(); break;
        case 'AIH': aihMarkModal.close(); break;
        default: throw new Error(`Invalid type (${type})`);
      }
    }
  };

  const oehMarkModal = useMarkOEHModal(onSaveMark, houseBLForm);
  const oihMarkModal = useMarkOIHModal(onSaveMark, houseBLForm);
  const aehMarkModal = useMarkAEHModal(onSaveMark, houseBLForm);
  const aihMarkModal = useMarkAIHModal(onSaveMark, houseBLForm);
  const invoiceARPrintModal = useInvoiceARPreviewModal();
  const invoiceAPPrintModal = useInvoiceAPPreviewModal();
  const invoiceDCPrintModal = useInvoiceDCPreviewModal();

  const onMarkDesc = async () => {
    if (!currentId) return;
    const res = await api.forwardViewBLMark(currentId);
    if (res?.data?.length !== 1) return;
    const {id, bl_no, filing_no, h_m, jmark = {}} = res['data'][0];
    const data = {id, bl_no, filing_no, h_m, ...jmark};
    switch (type) {
      case 'OEH': oehMarkModal.open('edit', data); break;
      case 'OIH': oihMarkModal.open('edit', data); break;
      case 'AEH': aehMarkModal.open('edit', data); break;
      case 'AIH': aihMarkModal.open('edit', data); break;
      default: throw new Error(`Invalid type (${type})`);
    }
  };

  const onEmail = () => {
    const blId = id ? util.toInt(id) : 0;
    const partnerId = bound === 'E' ? customerForm.getValue('shipperId') : customerForm.getValue('consigneeId');
    const customerEmail = bound === 'E' ? customerForm.getValue('shipperEmail') : customerForm.getValue('consigneeEmail');
    emailModal.open(blId, partnerId, customerEmail, sendEmailModal);
  };

  const onFlag = () => {
    apiForwarding.setBLFlag({bl_id: util.toInt(id), flag: !isFlag}).then((res) => {
      if (res) {
        setIsFlag(!isFlag);
        return util.showSuccess('Flag has been updated successfully!');
      }
    });
  };

  const onPackingList = () => {
    packingModal.open('add');
  };

  const onPrint = () => {
    if (carrierType === 'O') {
      oehBLPrintModal.open();
    } else {
      aehBLPrintModal.open();
    }
  };

  const onSave = async () => {
    const houseBLFormValues = houseBLForm.getValues();
    const customerFormValues = customerForm.getValues();
    const workDetailsFormValues = workDetailsForm.getValues();
    const values = {...houseBLFormValues, ...customerFormValues, ...workDetailsFormValues, id: id ? util.toInt(id) : undefined, qid: qid ? util.toInt(qid) : undefined};
    const data = getBLData(false, values);
    const payload = {...data, filingNo}
    if (!payload.jcustomer.shipperId && !payload.jcustomer.consigneeId) {
      return util.showWarning('Please enter a Shipper or Consignee data!');
    }
    setShowLoading(true);
    await apiForwarding.setForwardBL(payload).then(res => {
      if (res) {
        util.nav(`${pathname}?id=${res.data.id}`);
        util.showSuccess('House BL has been saved successfully!');
        setShowLoading(false);
        onSearch().catch();
      }
    });
  };

  const onCopy = async () => {
    util.openTab(`/admin/forwarding/house/customer?cid=${id}`);
  };

  const renderButton = () => {
    const isShowSave = clickedIndex === 1 || clickedIndex === 2;
    return (
      <div className={'flex'} style={{justifyContent: 'center', marginTop: 30}}>
        {isEdit && <div className={'mr-2'}><PrimaryButton label={'COPY'} disabled={!isEdit} onClick={onCopy} noMargin/></div>}
        {kind === 'F' && <div className={'mr-2'}><PrimaryButton label={'MARK & DESC'} disabled={!isEdit} onClick={onMarkDesc}/></div>}
        <div className={'mr-2'}><PrimaryButton label={'EMAIL'} disabled={!isEdit} onClick={onEmail}/></div>
        <div className={'mr-2'}>
          {isFlag ? <DangerButton label={'UNFLAG'} disabled={!isEdit} onClick={onFlag}/> : <PrimaryButton label={'FLAG'} disabled={!isEdit} onClick={onFlag}/>}
        </div>
        <div className={'mr-2'}><PrimaryButton label={'PACKING LIST'} disabled={!isEdit} onClick={onPackingList}/></div>
        {bound === 'E' && <div className={'mr-2'}><PrimaryButton label={'B/L PRINT'} disabled={!isEdit} onClick={onPrint}/></div>}
        {isShowSave && <div><PrimaryButton label={'SAVE'} onClick={onSave} noMargin/></div>}
      </div>
    );
  };

  const emailModal = useEmailModal();
  const sendEmailModal = useSendEmailModal(onSendEmail, id, houseBLForm);
  const {packingModal, packingListPrintModal} = useForwardingHouseBL(houseBLForm, customerForm, workDetailsForm);
  const oehBLPrintModal = useOEHBLPrintModal(true, houseBLForm, customerForm, workDetailsForm);
  const aehBLPrintModal = useAEMBLPrintModal(false, true, houseBLForm, customerForm, workDetailsForm);

  React.useEffect(() => {
    onSearchCopy().catch();
  }, [cid]);

  React.useEffect(() => {
    onSearch().catch();
  }, [id, mblId]);

  React.useEffect(() => {
    if (pathname === TABS[1].path) {
      setClickedIndex(TABS[1].index);
      customerForm.isShowForm = false;
      workDetailsForm.isShowForm = true;
      workOrdersForm.isShowForm = true;
      accountARItemForm.isShowForm = false;
      accountAPItemForm.isShowForm = false;
      accountDCItemForm.isShowForm = false;
      workOrdersForm.blId = id;
      setIsShowAccountList(false);
    } else if (pathname === TABS[2].path) {
      setClickedIndex(TABS[2].index);
      customerForm.isShowForm = false;
      workDetailsForm.isShowForm = false;
      workOrdersForm.isShowForm = false;
      accountARItemForm.isShowForm = true;
      accountAPItemForm.isShowForm = false;
      accountDCItemForm.isShowForm = false;
      accountARItemForm.blId = id;
      accountARItemForm.invoiceARPrintModal = invoiceARPrintModal;
      accountARItemForm.blData = blData;
      accountARItemForm.billToData = {billToId: customerForm.getValue('consigneeId'), billToName: customerForm.getValue('consigneeLocal')};
      setIsShowAccountList(false);
    } else if (pathname === TABS[3].path) {
      setClickedIndex(TABS[3].index);
      customerForm.isShowForm = false;
      workDetailsForm.isShowForm = false;
      workOrdersForm.isShowForm = false;
      accountARItemForm.isShowForm = false;
      accountAPItemForm.isShowForm = true;
      accountDCItemForm.isShowForm = false;
      accountAPItemForm.blId = id;
      accountAPItemForm.invoiceAPPrintModal = invoiceAPPrintModal;
      accountAPItemForm.blData = blData;
      setIsShowAccountList(false);
    } else if (pathname === TABS[4].path) {
      setClickedIndex(TABS[4].index);
      customerForm.isShowForm = false;
      workDetailsForm.isShowForm = false;
      workOrdersForm.isShowForm = false;
      accountARItemForm.isShowForm = false;
      accountAPItemForm.isShowForm = false;
      accountDCItemForm.isShowForm = true;
      accountDCItemForm.blId = id;
      accountDCItemForm.invoiceDCPrintModal = invoiceDCPrintModal;
      accountDCItemForm.blData = blData;
      setIsShowAccountList(false);
    } else if (pathname === TABS[5].path) {
      setClickedIndex(TABS[5].index);
      setIsShowAccountList(true);
    } else {
      setClickedIndex(TABS[0].index);
      customerForm.isShowForm = true;
      workDetailsForm.isShowForm = false;
      workOrdersForm.isShowForm = false;
      accountARItemForm.isShowForm = false;
      accountAPItemForm.isShowForm = false;
      accountDCItemForm.isShowForm = false;
      customerForm.houseBLForm = houseBLForm;
      setIsShowAccountList(false);
    }
    if (id) {
      util.nav(`${pathname}?id=${id}`);
    } else if (qid) {
      accountARItemForm.qid = qid;
      util.nav(`${pathname}?qid=${qid}`);
    } else if (mblId) {
      util.nav(`${pathname}?mblId=${mblId}`);
    } else {
      util.nav(pathname);
    }
  }, [id, qid, mblId, pathname, blData]);

  return (
    <>
      <div style={{marginLeft: -20}}>
        <div className={'flex'} style={{justifyContent: 'space-between'}}>
          <div className={'flex flex-1'} style={{marginLeft: 40, color: 'red'}}>
            <div className={'flex-1'}>
              <span>DEPOSIT: </span>
              {deposit?.map((data) => <span style={{marginRight: 20}}>{data}</span>)}
            </div>
            <div className={'flex-2'}>
              <span>AR: </span>
              {arData?.map((data) => <span style={{marginRight: 20}}>{data}</span>)}
            </div>
          </div>
          <div style={{marginTop: -25}}>
            {util.renderFooter(footer, 'new-house-footer')}
          </div>
        </div>
        {houseBLForm.renderForm()}
      </div>
      <hr/>
      <div style={{marginTop: 20}}>
        {showLoading && <LoadingContainer><ReactLoading color={'grey'} type={'spin'} height={100} width={100}/></LoadingContainer>}
        <div className={'flex'} style={{marginLeft: -2}}>
          <TabButton style={{backgroundColor: clickedIndex === TABS[0].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[0].index)}>
            <div>CUSTOMER</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[1].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[1].index)}>
            <div>WORK DETAILS</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[2].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[2].index)}>
            <div>AR</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[3].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[3].index)}>
            <div>AP</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[4].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[4].index)}>
            <div>DC</div>
          </TabButton>
          <TabButton style={{backgroundColor: clickedIndex === TABS[5].index ? '#b4c7e7' : 'white'}} onClick={() => onClickTab(TABS[5].index)}>
            <div>AR / AP / DC LIST</div>
          </TabButton>
        </div>
        <div style={{backgroundColor: 'white', minHeight: clickedIndex === TABS[5].index ? 200 : 580, border: clickedIndex === TABS[5].index ? '' : '1px solid'}}>
          {customerForm.renderForm()}
          {workDetailsForm.renderForm()}
          {clickedIndex === TABS[1].index && <hr className={'ml-3 mr-3'} style={{marginBottom: 0}}/>}
          {workOrdersForm.renderForm()}
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>
                <div className={'mr-1'}>{accountARItemForm.renderForm()}</div>
              </div>
            </div>
          </div>
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>
                <div className={'mr-1'}>{accountAPItemForm.renderForm()}</div>
              </div>
            </div>
          </div>
          <div>
            <div className={'flex w-full'}>
              <div className={'flex-1'}>
                <div className={'mr-1'}>{accountDCItemForm.renderForm()}</div>
              </div>
            </div>
          </div>
          <HouseBLAccountList isShowList={isShowAccountList} onSearch={onSearch}/>
        </div>
        {clickedIndex === TABS[5].index &&
          <div className={'flex right mt-2'}>
            <div className={'mr-4'}>{`AR Total: ${profitArray.length > 0 ? profitArray[0] : 0}`}</div>
            <div className={'mr-4'}>{`AP Total: ${profitArray.length > 0 ? profitArray[1] : 0}`}</div>
            <div className={'mr-2'}>{`Margin: ${profitArray.length > 0 ? profitArray[2] : 0}`}</div>
          </div>
        }
        {clickedIndex !== TABS[5].index && renderButton()}
        {emailModal.render()}
        {sendEmailModal.render()}
        {packingModal.render()}
        {oehBLPrintModal.render()}
        {aehBLPrintModal.render()}
        {packingListPrintModal.render()}
        {oehMarkModal.render()}
        {oihMarkModal.render()}
        {aehMarkModal.render()}
        {aihMarkModal.render()}
        {invoiceARPrintModal.render()}
        {invoiceAPPrintModal.render()}
        {invoiceDCPrintModal.render()}
      </div>
    </>
  );
};

function useForwardingHouseBL(houseBLForm, customerForm, workDetailsForm) {
  const packingListPrintModal = usePackingListPreviewModal();
  const {...packingModal} = useForwardingHouseBLPackingListModal(packingListPrintModal, houseBLForm, customerForm, workDetailsForm);
  return {packingModal, packingListPrintModal};
}

export default ForwardingHouseBL;
