/**
 * @flow
 */
import React from 'react';
import {util} from '../../services/service';
import {useSimpleGridModal} from '../../components/SimpleModal';
import MagnifyIcon from "mdi-react/MagnifyIcon";
import {kindOptions} from "../../forms/field-defs/newCoship";
import {apiForwarding, apiCourier, apiMoving} from "../../services/newCoship/service";

export function useMasterBLHouseListModal(getSelectedData, gridData, service) {
  const [queryData, setQueryData] = React.useState();
  const [totalCount, setTotalCount] = React.useState();
  const [pickupDate, setPickupDate] = React.useState(undefined);
  const [kind, setKind] = React.useState(service);
  const [qryText, setQueryText] = React.useState(undefined);
  const [dataSource, setDataSource] = React.useState([]);
  const defaultKind = service === 'M' ? undefined : service;

  async function getHouseList() {
    const payload = {...queryData, pickup_date: pickupDate, kind, search_word: qryText};
    const newApi = kind === 'M' ? apiMoving : (kind === 'F' ? apiForwarding : apiCourier);
    const res = await newApi.getBulkHouse(payload);
    const {data} = res;
    const gridBls = gridData?.map(data => data.bl_id);
    const newData = data.filter(x => !gridBls.includes(x.bl_id));
    modal.setRows(newData);
    setTotalCount(newData.length);
    setDataSource(newData);
  }
  function onAdd() {
    const selectedRows = modal.grid.apiRef.current.getSelectedRows();
    getSelectedData(selectedRows);
    const selectedBls = selectedRows.map((x) => x.bl_id);
    const newData = dataSource.filter(x => !selectedBls.includes(x.bl_id));
    modal.setRows(newData);
    setTotalCount(newData.length);
    setDataSource(newData);
    service !== 'M' && modal.close();
  }
  const onEnter = async () => {
    await getHouseList();
  };
  const onChangePickupDate = (e) => {
    const value = e.target.value;
    setPickupDate(value);
  };
  const onChangeService = (e) => {
    const value = e.target.value;
    setKind(value === 'All' ? undefined : value);
  };
  const onChangeQueryText = (value) => {
    setQueryText(value ? value : undefined);
  };
  const modal = useSimpleGridModal({
    title: 'HOUSE LIST',
    titleComponent: () => (
      <>
        <div className={'card__title no-margin pt-20 pl-20'}>
          <h5 className="bold-text">SEARCH HOUSE LIST</h5>
        </div>
        <div className={'theme-light ltr-support flex between middle px-20'}>
          <div className={'mt-8'}>{totalCount === undefined ? '' : `${modal.grid?.rows?.length ?? 0} of ${totalCount}`}</div>
          <div className={'flex'}>
            <input type={'date'} onChange={onChangePickupDate}/>
            <div className={'ml-20'}>
              <select id={'kind'} name={'kind'} style={{width: 150, height: 28}} defaultValue={defaultKind} onChange={onChangeService}>
                <option value={'All'}>ALL</option>
                {kindOptions.map(({value, label}) => <option key={value} value={value}>{label}</option>)}
              </select>
            </div>
            <div className={'inbox__emails-control-search flex middle ml-20'}>
              <input
                id={`house-search`}
                placeholder={'Search...'}
                value={qryText ?? ''}
                onChange={({target: {value}}) => onChangeQueryText(value)}
                onKeyUp={({target: {value}, key}) => {
                  if (key === 'Enter') {
                    onEnter(value).catch();
                  }
                }}
                style={{width: 200}}
              />
              <a href={'/#search'} className="inbox__emails-control-search-icon" onClick={(e) => {
                e.preventDefault();
                onEnter(document.querySelector('#house-search').value).catch();
              }}>
                <MagnifyIcon/>
              </a>
            </div>
          </div>
        </div>
      </>
    ),
    centered: true,
    width: 920,
    height: 700,
    buttons: [{label: 'Add', onClick: onAdd, color: 'primary'}],
    gridProps: {
      columns: [
        {headerName: '', checkboxSelection: true, headerCheckboxSelection: true, width: 50, minWidth: 50},
        {field: 'id', hide: true},
        {field: 'bl_no', headerName: 'HB/L No.', flex: 1},
        {field: 'kind', headerName: 'Service Type', sortable: true, unSortIcon: true, valueFormatter: (p) => util.labelFromOptions(p.value, kindOptions), flex: 1},
        {field: 'customer', headerName: 'Customer', sortable: true, unSortIcon: true, flex: 1},
        {field: 'consignee', headerName: 'Consignee', sortable: true, unSortIcon: true, flex: 1},
        {field: 'packages', headerName: 'Package', width: 100},
        {field: 'cbm', headerName: 'CBM', valueFormatter: util.formatCBM, width: 100},
        {field: 'weight_kg', headerName: 'Weight', valueFormatter: util.formatCBM, width: 100},
      ],
      actions: [],
      actionWidth: 0,
      onAction: (action, data) => {},
      rows: [],
      height: 650,
      className: 'mb-20',
      agGridProps: {
        rowSelection: 'multiple',
        rowMultiSelectWithClick: true,
        onRowClicked(e) {}
      }
    },
    children: null,
  });

  React.useEffect(() => {
    if (modal.isOpen) {
      getHouseList().catch(console.error);
      document.querySelector('#house-search').focus();
    } else {
      setPickupDate(undefined);
      setKind(defaultKind);
      setQueryText(undefined);
    }
  }, [modal.isOpen, pickupDate, kind]);

  return {
    ...modal,
    open: (newQueryData) => {
      setQueryData(newQueryData);
      modal.open();
    },
  };
}
