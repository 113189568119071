/**
 * @flow
 */
import {api, util} from '../../services/service';
import {useSimpleGrid} from "../../components/SimpleGrid";
import {INVOICE} from "../../forms/field-defs/account";
import React from "react";
import {useUser} from "../../redux/reducers/userReducer";

export default function useAccountListGridView(onAction) {
  const user = useUser();
  const userBranchIdRef = React.useRef();
  const isAccountTeamRef = React.useRef();
  const getColumns = () => {
    return [
      {field: 'acc_type', headerName: 'Type', width: 70},
      {field: 'inv_no', headerName: 'Inv. No.', width: 130, cellRendererFramework: (param) => util.accountLinkFormatter(param, '', true)},
      {field: 'partner_name', headerName: 'Bill To / Partner', flex: 1},
      {field: 'inv_date', headerName: 'Inv. Date', width: 120},
      {field: 'due_date', headerName: 'Due Date', width: 120},
      {field: 'currency', headerName: 'Currency', width: 120},
      {field: 'amount', headerName: 'Amount', width: 120, valueFormatter: util.currencyFormatter},
      {field: 'balance', headerName: 'Balance', width: 120, valueFormatter: util.currencyFormatter},
      {field: 'paid', headerName: 'Paid', width: 120, valueFormatter: util.currencyFormatter},
    ];
  };
  const actions = ['commission'];

  React.useEffect(() => {
    userBranchIdRef.current = user.branch_id;
    isAccountTeamRef.current = user.team_main === 'A';
  }, [user]);

  return useSimpleGrid({
    columns: getColumns(),
    height: 200,
    actionWidth: 70,
    actions,
    onAction: onAction,
    isActionDisabled: (action, data) => {
      const isShowPayment = getBranchName(userBranchIdRef.current) !== 'TORONTO';
      return !((data.acc_type === INVOICE.TYPE_AR || data.acc_type === INVOICE.TYPE_CM || data.acc_type === INVOICE.TYPE_VD) && isAccountTeamRef.current && isShowPayment);
    },
  });
}

const getBranchName = (branchId) => {
  const branchOptions = api.useAreaOptions(true);
  return branchOptions?.find(i => i.value === parseInt(branchId))?.label;
}
