/**
 * @flow
 */
import React from 'react';
import FormManager from '../../../lib/FormManager';
import {api, apiBL, util} from '../../../services/service';
import {FD, FN} from '../../field-defs/newCoship';
import {
  setBound,
  setBranch,
  setCarrierType, setFilingNo,
  setFlightData,
  setKind, setMawbNo
} from "../../../redux/reducers/newCoshipReducer";
import {useUser} from "../../../redux/reducers/userReducer";
import {useSelector} from "react-redux";
import {DefaultButton} from "../../../components/buttons";

class CourierHouseBLFormManager extends FormManager {
  constructor() {
    super({
      prefix: `courier-house-form`,
      fields: [
        FD.ID, FD.HBL_NO, FD.HAWB_NO, FD.BRANCH_ID, FD.TRADE_TYPE, FD.MBL_NO, FD.MAWB_NO, {...FD.PORT_TYPE, defaultValue: 'A'},
        {...FD.KIND, defaultValue: 'C'}, FD.BL_STATUS, FD.P_DATE, FD.P_TIME, FD.FLIGHT_DATE, FD.ETD, FD.ETA, FD.MAPLE_TYPE,
      ],
      formProps: {
        horizontal: true,
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  };
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c} = this;
    const qs = util.getQS();
    const {id} = qs;
    const user = useUser();
    const [carrier, setCarrier] = React.useState();
    const [service, setService] = React.useState();
    const [masterOptions, setMasterOptions] = React.useState([]);
    const blStatus = _v(FN.BL_STATUS);
    const branchId = _v(FN.BRANCH_ID);
    const {kind, carrierType, bound, filingNo, mawbNo} = useSelector(state => state['newCoship']);
    const branchOptions = api.useAreaOptions(true);
    _f(FN.BRANCH_ID).options = branchOptions;
    _f(FN.FLIGHT_DATE).disabled = true;
    _f(FN.KIND).onChange = (_, value) => onChangeKind(value);
    _f(FN.TRADE_TYPE).onChange = (_, value) => onChangeBound(value);
    _f(FN.BL_STATUS).onChange = (_, value) => onChangeBlStatus(value);
    _f(FN.PORT_TYPE).onChange = (_, value) => onChangeCarrierType(value);
    _f(FN.MAWB_NO).options = masterOptions;
    _f(FN.MAWB_NO).onChange = (_, value) => onChangeMawbNo(value);
    const onChangeKind = (value) => {
      _c(FN.KIND, value);
      setService(value);
      setKind(util.dispatch, value);
    };

    const onChangeBound = (value) => {
      _c(FN.TRADE_TYPE, value);
      setBound(util.dispatch, value);
    };

    const onChangeBlStatus = (value) => {
      _c(FN.BL_STATUS, value);
    };

    const onChangeCarrierType = (value) => {
      _c(FN.PORT_TYPE, value);
      setCarrier(value);
      setCarrierType(util.dispatch, value);
    };

    const onChangeMawbNo = (value) => {
      _c(FN.MAWB_NO, value);
      if (value) {
        apiBL.getCraftInfo(value).then(res => {
          const data = res.data[0];
          if(data) {
            _c(FN.FLIGHT_DATE, data.jextra.etdDate);
            const flightData = {flightNo: data.vol_voy, flightDate: data.jextra.etdDate, arrivalDate: data.jextra.etaDate, departure: data.pol, destination: data.pod};
            setFlightData(util.dispatch, flightData);
            setFilingNo(util.dispatch, data.filing_no);
          }
        });
      }
    };

    const getMasterOptions = () => {
      let options = [];
      if (bound && carrierType === 'A' && branchId && kind) {
        options.push({key: mawbNo, value: mawbNo, label: mawbNo, filingNo, mawbNo});
        apiBL.getMasterList({carrierType, bound, branchId, kind}).then((res) => {
          const {data: {lists = []}} = res;
          for (const [masterNo, filingNo] of lists) {
            if (masterNo) {
              options.push({key: masterNo, value: masterNo, label: masterNo, filingNo, masterNo});
            }
          }
          setMasterOptions([{key: undefined, value: undefined, label: undefined, filingNo: undefined, masterNo: undefined}, ...options]);
        });
      } else {
        setMasterOptions([]);
      }
    };

    const onDeleteMawbNo = () => {
      const mawbNo = _v('mawbNo');
      if (mawbNo) {
        util.showConfirm('Are you sure to delete Master No?', () => {
          const id = _v(FN.ID);
          if (id) {
            apiBL.resetMbl(util.toInt(id)).catch();
          }
          _c(FN.MAWB_NO, '');
          _c(FN.FLIGHT_DATE, undefined);
          setFlightData(util.dispatch, {});
          setFilingNo(util.dispatch, undefined);
          setMawbNo(util.dispatch, undefined);
          setMasterOptions([]);
        });
      }
    };

    React.useEffect(() => {
      let branchId = _v(FN.BRANCH_ID);
      branchId = branchId ? util.toInt(branchId) : branchId;
      if (!branchId) {
        _c(FN.BRANCH_ID, user.branch_id);
      }
      const branch = branchOptions.find(x => x.value === branchId)?.label;
      setBranch(util.dispatch, branch);
    }, [user.branch_id, _v(FN.BRANCH_ID), branchOptions]);

    React.useEffect(() => {
      getMasterOptions();
    }, [carrier, service, bound, branchId, mawbNo]);

    React.useEffect(() => {
      setCarrier(carrierType);
    }, [carrierType]);

    React.useEffect(() => {
      if (!blStatus) {
        _c(FN.BL_STATUS, 'NG');
      }
    }, [blStatus, user.branch_id, branchOptions]);

    React.useEffect(() => {
      if (!_v(FN.KIND)) {
        setService('C');
        _c(FN.KIND, 'C');
        setKind(util.dispatch, 'C');
      }
    }, [_v(FN.KIND), user.branch_id, branchOptions]);

    React.useEffect(() => {
      if (!_v(FN.PORT_TYPE)) {
        setCarrier('A');
        _c(FN.PORT_TYPE, 'A');
        setCarrierType(util.dispatch, 'A');
      }
    }, [_v(FN.PORT_TYPE), user.branch_id, branchOptions]);

    React.useEffect(() => {
      if (!_v(FN.TRADE_TYPE)) {
        _c(FN.TRADE_TYPE, 'E');
        setBound(util.dispatch, 'E');
      }
    }, [_v(FN.TRADE_TYPE), user.branch_id, branchOptions]);

    React.useEffect(() => {
      if (!id) {
        _c(FN.P_DATE, util.getCurrentDate());
      }
    }, [user.branch_id, branchOptions]);

    return(
      <div style={{width: 'calc(100% + 8px)'}}>
        <div className={'flex w-full mt-2'}>
          <div className={'flex-1 mr-2'}>{_r(FN.P_DATE)}</div>
          {carrier !== 'A' && <div className={'flex-1 mr-2'}>{_r(FN.HBL_NO)}</div>}
          {carrier === 'A' && <div className={'flex-1 mr-2'}>{_r(FN.HAWB_NO)}</div>}
          {carrier === 'O' && <div className={'flex-1 mr-2'}>{_r(FN.ETD)}</div>}
          {(carrier === 'A' && service === 'C') && <div className={'flex-1'}>{_r(FN.FLIGHT_DATE)}</div>}
          <div className={'flex-1 mr-2'}>{_r(FN.KIND)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.TRADE_TYPE)}</div>
          <div className={'flex-1'}>{_r(FN.BL_STATUS)}</div>
        </div>
        <div className={'flex w-full'}>
          <div className={'flex-1 mr-2'}>{_r(FN.P_TIME)}</div>
          {carrier === 'A' && <div className={'flex-1 mr-2'}>{_r(FN.MAWB_NO)}</div>}
          {carrier === 'G' && <div className={'flex-1 mr-2'}>{_r(FN.MBL_NO)}</div>}
          {carrier === 'O' && <div className={'flex-1 mr-2'}>{_r(FN.MAPLE_TYPE)}</div>}
          {carrier === 'O' && <div className={'flex-1 mr-2'}>{_r(FN.ETA)}</div>}
          {(carrier === 'A' && service === 'C') && <div className={'flex-1 mt-1'}><DefaultButton label={'Reset'} onClick={onDeleteMawbNo}/></div>}
          <div className={'flex-1 mr-2'}>{_r(FN.BRANCH_ID)}</div>
          <div className={'flex-1 mr-2'}>{_r(FN.PORT_TYPE)}</div>
          <div className={'flex-1'}>{''}</div>
        </div>
      </div>
    );
  };
  onValidate = (values) => {};
}

export default CourierHouseBLFormManager;
