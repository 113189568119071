/**
 * @flow
 */
import React from 'react';
import {api, util} from "../services/service";
import {useDataGridView} from "../components/DataGridView";
import {useSimpleModal} from "../components/SimpleModal";
import {queryGridData} from "../components/DataGrid";
import {closeTPSearchModal} from "../components/SearchInput";

export default function usePartnerHistoryModal() {
  const [data, setData] = React.useState(); // 그리드에서 선택한 행의 데이터를 보관함
  async function onQuery(gridInfo) {
    if (modal.isOpen) {
      const dataInfo = {...gridInfo, ...data};
      return await api.forwardTradePartnerHistoryList(dataInfo);
    }
  }
  const grid = useDataGridView({
    async onAction(action, data) {
      if(action === 'copy') {
        modal.close();
        closeTPSearchModal();
        // util.nav(`/admin/bl/house?id=${data.id}`);
        util.openTab(`/admin/${data.kind_str}/house/customer?cid=${data.id}`);
      }
    },
    onQuery,
    onCellValueChange(e) {
    },
    onCellClick({data, column: {colDef: {headerName}}}) {
    },
    name: `Partner-History`,
    label: '',
    addLabel: '',
    editLabel: '',
    categoryLabel: {label: 'Partner'},
    menuLabel: {label: 'List'},
    sortCol: 'cdate',
    sortDesc: true,
    pageRows: 20,
    actions: ['copy'],
    actionWidth: 60,
    showDateSearch: false,
    showDeleteButton: false,
    hideSearchInput: false,
    sortableColumns: {}, // 정렬 되는 / 정렬되지 않는 컬럼 목록을 정의
    useExternalForm: true,
    useModalDataGrid: true,
    modalDataGridHeight: 500,
    showRowSearch: false,
    padding: 10,
    columns: [
      {field: 'id', hide: true},
      {field: 'post_date', headerName: 'Post Date', valueFormatter: util.dateFormatter, flex: 1},
      {field: 'bl_no', headerName: 'B/L No.', cellRendererFramework: param => util.blHouseLinkFormatter(param, true), flex: 1},
      {field: 'branch_name', headerName: 'Branch', flex: 1},
      {field: 'shipper', headerName: 'Shipper', flex: 1},
      {field: 'consignee', headerName: 'Consignee', flex: 1},
      {field: 'remark_d', headerName: 'Remark(D)', flex: 1},
    ],
  });
  const modal = useSimpleModal({
    title: 'Partner History',
    centered: true,
    width: 800,
    buttons: [],
    children: grid.render()
  });
  React.useEffect(() => {
    if (modal.isOpen === true) {
        queryGridData(grid.props.name, undefined, onQuery).then(() => {console.log('hello, grid query called!');});
    }
  }, [modal.isOpen]);
  const open = (data: Object) => {
    setData(data);
    modal.open();
  };
  return {...modal, open};
}
