/**
 * @flow
 */
import React from "react";
import FormManager from "../../../lib/FormManager";
import {api, util} from "../../../services/service";
import {oceanAirOptionsForBLList, searchKeyOptions} from "../../helper";
import {kindOptions} from "../../field-defs/bl";
import {useUser} from "../../../redux/reducers/userReducer";
import Icon from "mdi-react/SearchIcon";
import {TradePartnerSearchInput} from "../../../components/SearchInput";

export const SEARCH = 'qryText';
export const FROM_DATE = 'fromDate';
export const TO_DATE = 'toDate';
export const KIND = 'kind';
export const BRANCH = 'branchId';
export const CARRIER_TYPE = 'carrierType';
export const DEPARTURE = 'departure';
export const DESTINATION = 'destination';
export const SEARCH_NAME = 'searchName';
export const SEARCH_VALUE = 'searchVal';
export const PARTNER = 'partner';
export const PARTNER_ID = 'partnerId';
export const STORAGE = 'storage';
export const CUSTOMER_ONLY = 'customer_only';
export const FLAG = 'flag';

export default class CourierBLListSearchFormManager extends FormManager {
  onSearch: () => void;
  partnerData;
  clickedIndex;
  constructor() {
    super({
      prefix: 'courier-bl-list-search',
      fields: [
        {name: SEARCH, serverName: SEARCH, label: 'Search', smallMargin: true},
        {name: FROM_DATE, serverName: FROM_DATE, label: 'Date', smallLabel: true, smallMargin: true, type: 'date'},
        {name: TO_DATE, serverName: TO_DATE, label: '', smallLabel: true, smallMargin: true, type: 'date'},
        {name: KIND, serverName: KIND, label: 'Service', options: [{value: '', label: 'ALL'}, ...kindOptions], noDefOption: true, smallMargin: true},
        {name: BRANCH, serverName: BRANCH, label: 'Branch', noDefOption: true, smallMargin: true},
        {name: CARRIER_TYPE, serverName: CARRIER_TYPE, label: 'Carrier Type', options: [{value: '', label: 'ALL'}, ...oceanAirOptionsForBLList], noDefOption: true, smallMargin: true},
        {name: DEPARTURE, serverName: DEPARTURE, label: 'Departure', smallMargin: true},
        {name: DESTINATION, serverName: DESTINATION, label: 'Destination', smallMargin: true},
        {name: SEARCH_NAME, serverName: SEARCH_NAME, label: 'Search by', options: searchKeyOptions, noDefOption: true, smallMargin: true, smallLabel: true},
        {name: SEARCH_VALUE, serverName: SEARCH_VALUE, label: '', smallMargin: true, smallLabel: true},
        {name: PARTNER, serverName: PARTNER, label: 'Partner'},
        {name: PARTNER_ID, serverName: PARTNER_ID, label: ''},
        {name: STORAGE, serverName: STORAGE, type: 'checkbox', label: 'Storage', smallMargin: true},
        {name: CUSTOMER_ONLY, serverName: CUSTOMER_ONLY, type: 'checkbox', label: 'Customer Only', smallMargin: true},
        {name: FLAG, serverName: FLAG, type: 'checkbox', label: 'Flag', smallMargin: true},
      ],
      formProps: {
        doNotUseButtons: true,
        doNotUseCard: true,
      },
    });
  }
  onRender = () => {
    const {renderField: _r, getField: _f, getValue: _v, setValue: _c, _n} = this;
    const [formik, _, fields, errors] = this.renderParams;
    const areaOptions = api.useAreaOptions(true);
    const user = useUser();
    _f(BRANCH).options = [{value: '', label: 'ALL'}, ...areaOptions];
    _f(SEARCH).onEnter = this.onSearch;

    const kind = _v(KIND);
    const branchId = _v(BRANCH);
    const carrierType = _v(CARRIER_TYPE);

    const onClickSearch = (e) => {
      e.preventDefault();
      e.stopPropagation();
      this.onSearch();
    };

    const _sh = () => {
      return (
        <div className={`flex middle`}>
          {_r(SEARCH)}
          <div className={'ml-8 mt-16'}>
            <a href={'/#search'} onClick={onClickSearch}>
              <Icon size={20} />
            </a>
          </div>
        </div>
      );
    };

    const _tp = (name, vertical = false) => <TradePartnerSearchInput
      vertical forSmallLabel
      idField={_n(`${name}Id`)} displayField={_n(name)}
      formik={formik} fields={fields} errors={errors}
    />;

    React.useEffect(() => {
      _c(FROM_DATE, util.toDefaultFirstTS(util.getCurrentDate()));
      _c(TO_DATE, util.toDefaultEndTS(util.getCurrentDate()));
      // _c(KIND, 'C');
      const search = document.querySelector(`#${this.prefix}-qryText`);
      if (search) {
        search.focus();
      }
    }, []);

    React.useEffect(() => {
      if (!branchId) {
        _c(BRANCH, user.branch_id);
      }
    }, [user.branch_id]);

    React.useEffect(() => {
      if (!(kind === undefined && branchId === undefined && carrierType === undefined)) {
        this.onSearch();
      }
    }, [kind, branchId, carrierType, this.clickedIndex]);

    const searchKey = _v('searchName');
    React.useEffect(() => {
      if(!searchKey) {
        _c('searchName', searchKeyOptions[0].value);
      } else {
        _c('searchName', searchKey);
      }
    }, [searchKey]);

    return (
      <>
        {_sh()}
        {_r(FROM_DATE)}
        <div>~</div>
        {_r(TO_DATE)}
        {_r(KIND)}
        {_r(BRANCH)}
        {_r(CARRIER_TYPE)}
        {this.clickedIndex !== 1 && _r(DEPARTURE)}
        {this.clickedIndex !== 1 && _r(DESTINATION)}
        {_r(SEARCH_NAME)}
        {_r(SEARCH_VALUE)}
        {_tp(PARTNER)}
        <div>
          {this.clickedIndex === 1 && _r(STORAGE)}
          {this.clickedIndex === 1 && _r(CUSTOMER_ONLY)}
          {_r(FLAG)}
        </div>
      </>
    );
  };
}
