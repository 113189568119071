/**
 * @flow
 */
import React from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import MainWrapper from './MainWrapper';
import {api, util} from '../../services/service';

import LayoutContainer from '../../components/LayoutContainer';
import Login from '../LogIn/Login';
import Home from '../Home/Home';

import BusinessCustomerList from '../Customer/BusinessCustomerList';
import UserCustomerList from '../Customer/UserCustomerList';
import VendorCustomerList from '../Customer/VendorCustomerList';
import DropzoneCustomerList from '../Customer/DropzoneCustomerList';

import LoginLogList from '../Logs/LoginLogList';
import ActivityLogList from '../Logs/ActivityLogList';

import StaffListOld from '../Staffs/StaffListOld';
import StaffList from '../Staffs/StaffList';
import StaffPermission from '../Staffs/StaffPermission';
import StaffSchedule from '../Staffs/StaffSchedule';
import StaffFullSchedule from '../Staffs/StaffFullSchedule';
import StaffHolidayOld from '../Staffs/StaffHolidayOld';
import StaffHoliday from '../Staffs/StaffHoliday';
import EmailList from '../Staffs/EmailList';

import AreaList from '../Settings/AreaList';
import NoticeList from '../Settings/NoticeList';
import AccountTypeList from '../Settings/AccountTypeList';
import AirVesselList from '../Settings/AirVesselList';
import SettingMaster from '../Settings/SettingMaster';

import DashboardMessageList from '../Dashboard/DashboardMessageList';
// import DashboardSummary from '../Booking';
import DashboardSummary from '../Dashboard/summary';
import AccountInvoiceAR from '../Account/AccountInvoiceAR';
import AccountInvoiceAP from '../Account/AccountInvoiceAP';
import AccountInvoiceDC from '../Account/AccountInvoiceDC';
import AccountInvoiceCM from '../Account/AccountInvoiceCM';
import AccountInvoiceVD from '../Account/AccountInvoiceVD';
import AccountInvoiceDCNote from '../Account/AccountInvoiceDCNote';

import FlightList from '../Craft/FlightList';
import VesselList from '../Craft/VesselList';
import ContainerList from '../Craft/ContainerList';

import AccountList from '../Account/AccountList';

import BLList from '../BL/BLList';
import BLManifest from '../BL/BLManifest';
import BLHouse from '../BL/BLHouse';
import BLMaster from '../BL/BLMaster';
import SetWorkOrder from '../BL/SetWorkOrder';
import WorkOrderList from '../BL/WorkOrderList';
import BLReport from '../BL/BLReport';
import BLUps from '../BL/BLUps';
import BLSchedule from '../BL/BLSchedule';
import BLFullSchedule from '../BL/BLFullSchedule';
import WorkAssignList from '../BL/WorkAssignList';

import TradingPartnerView from '../Partner/TradingPartnerView';
import DashboardRequestList from '../Dashboard/DashboardRequestList';
import AccountBLRate from '../Account/AccountBLRate';
import AccountProfit from '../Account/AccountProfit';
import AccountCommission from '../Account/AccountCommission';
import AccountVendor from '../Account/AccountVendor';
import AccountDCNote from '../Account/AccountDCNote';
import AccountRequest from '../Account/AccountRequest';
import AccountListARPaid from '../Account/AccountListARPaid';
import DashboardUpcoming from '../Dashboard/DashboardUpcoming';

import MovingMasterBL from '../NewCoship/moving/MovingMasterBL';
import MovingHouseBL from '../NewCoship/moving/MovingHouseBL';
import MovingBLList from '../NewCoship/moving/MovingBLList';
import MovingSettingAccountTypeList from '../NewCoship/moving/MovingSettingAccountTypeList';

import ForwardingMasterBL from '../NewCoship/forwarding/ForwardingMasterBL';
import ForwardingHouseBL from '../NewCoship/forwarding/ForwardingHouseBL';
import ForwardingBLList from '../NewCoship/forwarding/ForwardingBLList';
import ForwardingQuote from '../NewCoship/forwarding/ForwardingQuote';
import ForwardingSettingAccountTypeList from '../NewCoship/forwarding/ForwardingSettingAccountTypeList';

import CourierMasterBL from '../NewCoship/courier/CourierMasterBL';
import CourierHouseBL from "../NewCoship/courier/CourierHouseBL";
import CourierBLList from '../NewCoship/courier/CourierBLList';
import Manifest from '../NewCoship/courier/Manifest';
import CLP from '../NewCoship/courier/CLP';
import CourierSettingAccountTypeList from '../NewCoship/courier/CourierSettingAccountTypeList';

const Router = () => {
  // const location = useLocation();
  const history = useHistory();
  util.history = history;
  api.history = history;
  // React.useEffect(() => {
  //   console.log('hello pathname changed', location.pathname);
  // }, [location.pathname]);
  return (
    <MainWrapper>
      <main>
        <Switch>
          <Route exact path={'/admin/login'} component={Login} />
          <LayoutContainer>
            <Switch>
              <Route exact path={'/'} render={() => <Redirect to={'/admin/dashboard/summary'} />} />
              <Route exact path={'/admin'} render={() => <Redirect to={'/admin/dashboard/summary'} />} />

              <Route exact path={'/admin/dashboard/message'} component={DashboardMessageList} />
              <Route exact path={'/admin/dashboard/request'} component={DashboardRequestList} />
              <Route exact path={'/admin/dashboard/summary'} component={DashboardSummary} />
              <Route exact path={'/admin/dashboard/upcoming'} component={DashboardUpcoming} />

              {/*<Route exact path={'/admin/moving/schedule'} component={MovingCalendarView} />*/}
              {/*<Route exact path={'/admin/moving/shipping'} component={MovingShipping} />*/}
              {/*<Route exact path={'/admin/moving/workorder'} component={MovingWorkOrder} />*/}
              {/*<Route exact path={'/admin/moving/list'} component={MovingListAll} />*/}
              <Route exact path={'/admin/moving/master'} component={MovingMasterBL} />
              <Route exact path={'/admin/moving/master/mbl'} component={MovingMasterBL} />
              <Route exact path={'/admin/moving/master/hbl'} component={MovingMasterBL} />
              <Route exact path={'/admin/moving/master/ap'} component={MovingMasterBL} />
              <Route exact path={'/admin/moving/house'} component={MovingHouseBL} />
              <Route exact path={'/admin/moving/house/customer'} component={MovingHouseBL} />
              <Route exact path={'/admin/moving/house/work'} component={MovingHouseBL} />
              <Route exact path={'/admin/moving/house/ar'} component={MovingHouseBL} />
              <Route exact path={'/admin/moving/house/ap'} component={MovingHouseBL} />
              <Route exact path={'/admin/moving/house/accountlist'} component={MovingHouseBL} />
              <Route exact path={'/admin/moving/bllist'} component={MovingBLList} />
              <Route exact path={'/admin/moving/bllist/house'} component={MovingBLList} />
              <Route exact path={'/admin/moving/bllist/master'} component={MovingBLList} />
              <Route exact path={'/admin/moving/bllist/inventory'} component={MovingBLList} />
              <Route exact path={'/admin/moving/bllist/upcoming'} component={MovingBLList} />
              <Route exact path={'/admin/moving/setting'} component={MovingSettingAccountTypeList} />

              <Route exact path={'/admin/forwarding/master'} component={ForwardingMasterBL} />
              <Route exact path={'/admin/forwarding/master/mbl'} component={ForwardingMasterBL} />
              <Route exact path={'/admin/forwarding/master/hbl'} component={ForwardingMasterBL} />
              <Route exact path={'/admin/forwarding/master/container'} component={ForwardingMasterBL} />
              <Route exact path={'/admin/forwarding/master/ap'} component={ForwardingMasterBL} />
              <Route exact path={'/admin/forwarding/house'} component={ForwardingHouseBL} />
              <Route exact path={'/admin/forwarding/house/customer'} component={ForwardingHouseBL} />
              <Route exact path={'/admin/forwarding/house/work'} component={ForwardingHouseBL} />
              <Route exact path={'/admin/forwarding/house/ar'} component={ForwardingHouseBL} />
              <Route exact path={'/admin/forwarding/house/ap'} component={ForwardingHouseBL} />
              <Route exact path={'/admin/forwarding/house/dc'} component={ForwardingHouseBL} />
              <Route exact path={'/admin/forwarding/house/accountlist'} component={ForwardingHouseBL} />
              <Route exact path={'/admin/forwarding/bllist'} component={ForwardingBLList} />
              <Route exact path={'/admin/forwarding/bllist/house'} component={ForwardingBLList} />
              <Route exact path={'/admin/forwarding/bllist/master'} component={ForwardingBLList} />
              <Route exact path={'/admin/forwarding/bllist/container'} component={ForwardingBLList} />
              <Route exact path={'/admin/forwarding/bllist/inventory'} component={ForwardingBLList} />
              <Route exact path={'/admin/forwarding/bllist/upcoming'} component={ForwardingBLList} />
              <Route exact path={'/admin/forwarding/quote'} component={ForwardingQuote} />
              <Route exact path={'/admin/forwarding/setting'} component={ForwardingSettingAccountTypeList} />

              <Route exact path={'/admin/courier/master'} component={CourierMasterBL} />
              <Route exact path={'/admin/courier/master/mbl'} component={CourierMasterBL} />
              <Route exact path={'/admin/courier/master/hbl'} component={CourierMasterBL} />
              <Route exact path={'/admin/courier/master/ap'} component={CourierMasterBL} />
              <Route exact path={'/admin/courier/house'} component={CourierHouseBL} />
              <Route exact path={'/admin/courier/house/customer'} component={CourierHouseBL} />
              <Route exact path={'/admin/courier/house/work'} component={CourierHouseBL} />
              {/*<Route exact path={'/admin/courier/house/account'} component={CourierHouseBL} />*/}
              <Route exact path={'/admin/courier/house/ar'} component={CourierHouseBL} />
              <Route exact path={'/admin/courier/house/ap'} component={CourierHouseBL} />
              <Route exact path={'/admin/courier/house/accountlist'} component={CourierHouseBL} />
              <Route exact path={'/admin/courier/bllist'} component={CourierBLList} />
              <Route exact path={'/admin/courier/bllist/house'} component={CourierBLList} />
              <Route exact path={'/admin/courier/bllist/master'} component={CourierBLList} />
              <Route exact path={'/admin/courier/bllist/inventory'} component={CourierBLList} />
              <Route exact path={'/admin/courier/bllist/upcoming'} component={CourierBLList} />
              <Route exact path={'/admin/courier/manifest'} component={Manifest} />
              <Route exact path={'/admin/courier/clp'} component={CLP} />
              <Route exact path={'/admin/courier/setting'} component={CourierSettingAccountTypeList} />

              {/*<Route exact path={'/admin/courier/list'} component={CourierListAll} />*/}
              {/*<Route exact path={'/admin/courier/schedule'} component={CourierCalendarView} />*/}
              {/*<Route exact path={'/admin/courier/shipping'} component={CourierShipping} />*/}
              {/*<Route exact path={'/admin/courier/workorder'} component={CourierWorkOrder} />*/}
              {/*<Route exact path={'/admin/courier/workassign'} component={CourierWorkAssigned} />*/}

              {/*<Route exact path={'/admin/ecommerce/list'} component={ECommerceListAll} />*/}
              {/*<Route exact path={'/admin/ecommerce/schedule'} component={ECommerceCalendarView} />*/}
              {/*<Route exact path={'/admin/ecommerce/shipping'} component={ECommerceShipping} />*/}
              {/*<Route exact path={'/admin/ecommerce/workorder'} component={ECommerceWorkOrder} />*/}

              {/*<Route exact path={'/admin/forward/partner'} component={TradingPartnerView} />*/}
              {/*<Route exact path={'/admin/forwarding/list'} component={ForwardingListAll} />*/}
              {/*<Route exact path={'/admin/forwarding/master/:id'} component={ForwardingNewMaster} />*/}
              {/*<Route exact path={'/admin/forwarding/master'} component={ForwardingNewMaster} />*/}
              {/*<Route exact path={'/admin/forwarding/house/:id'} component={ForwardingNewHouse} />*/}
              {/*<Route exact path={'/admin/forwarding/house'} component={ForwardingNewHouse} />*/}
              {/*<Route exact path={'/admin/forwarding/new-wo'} component={ForwardingNewWorkOrder} />*/}

              {/*<Route exact path={'/admin/account/craft'} component={AccountAirVessel} />*/}
              {/*<Route exact path={'/admin/account/cargo'} component={AccountCargo} />*/}
              <Route exact path={'/admin/account/type'} component={AccountTypeList} />
              <Route exact path={'/admin/account/list/ar'} component={AccountInvoiceAR} />
              <Route exact path={'/admin/account/list/ap'} component={AccountInvoiceAP} />
              <Route exact path={'/admin/account/list/dc'} component={AccountInvoiceDC} />
              <Route exact path={'/admin/account/list/cm'} component={AccountInvoiceCM} />
              <Route exact path={'/admin/account/list/vd'} component={AccountInvoiceVD} />
              <Route exact path={'/admin/account/list/dcnote'} component={AccountInvoiceDCNote} />
              <Route exact path={'/admin/account/list'} component={AccountList} />
              <Route exact path={'/admin/account/blrate'} component={AccountBLRate} />
              <Route exact path={'/admin/account/profit'} component={AccountProfit} />
              <Route exact path={'/admin/account/commission'} component={AccountCommission} />
              <Route exact path={'/admin/account/vendor'} component={AccountVendor} />
              <Route exact path={'/admin/account/dcnote'} component={AccountDCNote} />
              <Route exact path={'/admin/account/request'} component={AccountRequest} />
              <Route exact path={'/admin/account/arphistory'} component={AccountListARPaid} />

              <Route exact path={'/admin/settings/area'} component={AreaList} />
              <Route exact path={'/admin/settings/notice'} component={NoticeList} />
              <Route exact path={'/admin/settings/accounttype'} component={AccountTypeList} />
              <Route exact path={'/admin/settings/craft'} component={AirVesselList} />
              <Route exact path={'/admin/settings/master'} component={SettingMaster} />

              <Route exact path={'/admin/staffs/email'} component={EmailList} />
              {/*<Route exact path={'/admin/staffs/list'} component={StaffListOld} />*/}
              <Route exact path={'/admin/staffs/list'} component={StaffList} />
              <Route exact path={'/admin/staffs/permission'} component={StaffPermission} />
              {/*<Route exact path={'/admin/staffs/plan'} component={StaffSchedule} />*/}
              <Route exact path={'/admin/staffs/plan'} component={StaffFullSchedule} />
              {/*<Route exact path={'/admin/staffs/holiday'} component={StaffHolidayOld} />*/}
              <Route exact path={'/admin/staffs/holiday'} component={StaffHoliday} />

              <Route exact path={'/admin/customers/business'} component={BusinessCustomerList} />
              <Route exact path={'/admin/customers/user'} component={UserCustomerList} />
              <Route exact path={'/admin/customers/vendor'} component={VendorCustomerList} />
              <Route exact path={'/admin/customers/dropzone'} component={DropzoneCustomerList} />

              <Route exact path={'/admin/logs/logins'} component={LoginLogList} />
              <Route exact path={'/admin/logs/activities'} component={ActivityLogList} />

              <Route exact path={'/admin/craft/flight'} component={FlightList} />
              <Route exact path={'/admin/craft/vessel'} component={VesselList} />
              <Route exact path={'/admin/craft/container'} component={ContainerList} />
              {/*<Route exact path={'/admin/craft/test'} component={Test} />*/}

              <Route exact path={'/admin/bl/list/:ts'} component={BLList} />
              <Route exact path={'/admin/bl/list'} component={BLList} />
              <Route exact path={'/admin/bl/house/:id'} component={BLHouse} />
              <Route exact path={'/admin/bl/house'} component={BLHouse} />
              <Route exact path={'/admin/bl/master/:id'} component={BLMaster} />
              <Route exact path={'/admin/bl/master'} component={BLMaster} />
              <Route exact path={'/admin/bl/manifest/:ts'} component={BLManifest} />
              <Route exact path={'/admin/bl/manifest'} component={BLManifest} />
              <Route exact path={'/admin/bl/report'} component={BLReport} />
              <Route exact path={'/admin/bl/ups'} component={BLUps} />

              <Route exact path={'/admin/schedule/schedule/:ts'} component={BLSchedule} />
              <Route exact path={'/admin/schedule/schedule'} component={BLSchedule} />
              <Route exact path={'/admin/schedule/fullschedule'} component={BLFullSchedule} />
              <Route exact path={'/admin/schedule/workorder/set'} component={SetWorkOrder} />
              <Route exact path={'/admin/schedule/workorder/:ts'} component={WorkOrderList} />
              <Route exact path={'/admin/schedule/workorder'} component={WorkOrderList} />
              <Route exact path={'/admin/schedule/workassign'} component={WorkAssignList} />

              <Route exact path={'/admin/partner/list'} component={TradingPartnerView} />

              <Route path={'/admin'} component={Home} />
            </Switch>
          </LayoutContainer>
        </Switch>
      </main>
    </MainWrapper>
  );
};

export default Router;
